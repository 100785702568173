<template>
  
  <div :class="{message_sidebar: true, enable: enable, worked: worked}" v-click-outside="toggle">

    <div class="ui active centered inline loader mini is_loading"></div>
 
    <ChatToggle
      :enable="enable"
      @toggle="toggle"
      />


 
    <ChatTitle 
      :worked="worked ? true : false"
      @addgroup="addGroupRoom"
      @close="toggle"
      />


    <ChatRooms 
      :tab="tab"
      :common="common" 
      :business="business" 
      :worked="worked ? true : false"
      @open="openRoom($event)"
      @loadcommon="loadRoomsCommonMore"
      @loadbusiness="loadRoomsBusinessMore"
      @toggletab="toggleTab"
      />
 

    <ChatRoom 
      v-if="opened_room"
      :room="opened_room"
      :worked="worked ? true : false"
      @closeRoom="closeRoom"
      @remove="removeRoom"
      @ban="removeRoom"
      @complain="removeRoom"
      @manage="manageRoom"
      @invite="inviteUsersRoom"
      @closeChat="toggle"
      />
 
 

    <ChatManage 
      v-if="manage"
      :room="opened_room"
      @close="closeManageRoom"
      />

    <ChatInviteUsers 
      v-if="invite"
      :room="opened_room"
      @close="closeInviteUsers"
      />

 


  </div>

  
</template>


<script setup>

const { $api, hook, $popup, $noscroll } = useNuxtApp(); 
const common = ref([]);
const common_cnt = ref(0);
const common_limit = ref(40);
const common_start = ref(0);
const common_end = ref(40);
const common_rooms_ended = ref(false);
const business = ref([]);
const business_cnt = ref(0);
const business_limit = ref(40);
const business_start = ref(0);
const business_end = ref(40);
const business_rooms_ended = ref(false);
const rooms_loading_state = ref(false);
const opened_room = ref(null);
const enable = ref(false);
const worked = ref(false);
const manage = ref(false);
const invite = ref(false);
const external = ref(false);
const state = ref(false);
const timer_refresher = ref(null);
const tab = ref('common');

const loadRoomsCommonMore = () => {

  if(!enable.value)
    return false;

  if(rooms_loading_state.value)
    return false;

  // if(common_rooms_ended.value)
  //   return false;


  common_end.value += common_limit.value;

  loadRoomsCommon();
}

const loadRoomsCommon = () => {

  if(!enable.value)
    return false;

  if(rooms_loading_state.value)
    return false;

  rooms_loading_state.value = true;
  
  $api.getChatCommonRooms(common_start.value, common_end.value+1).then((res) => {
    
    common.value = res.list_rooms;
    common_cnt.value = res.cnt_rooms;          

    rooms_loading_state.value = false;

    // if(common.value.length == common_cnt.value){
    //   common_rooms_ended.value = true;
    // }

  });

}

const loadRoomsBusinessMore = () => {

  if(!enable.value)
    return false;

  if(rooms_loading_state.value)
    return false;

  // if(business_rooms_ended.value)
  //   return false;

  business_end.value += business_limit.value;

  loadRoomsBusiness();
}

const loadRoomsBusiness = () => {


  if(!enable.value)
    return false;


  if(rooms_loading_state.value)
    return false;


  rooms_loading_state.value = true;

  $api.getChatBusinessRooms(business_start.value, business_end.value+1).then((res) => {
    business.value = res.list_rooms;
    business_cnt.value = res.cnt_rooms;   
    
    rooms_loading_state.value = false;

    // if(business.value.length == business_cnt.value){
    //   business_rooms_ended.value = true;
    // }

  });

}

const toggleTab = (id) => {
  tab.value = id;
}

const loadRooms = () => {
  if(tab.value == 'common')
    loadRoomsCommon();
  else
    loadRoomsBusiness();
}

const reloadRooms = () => {
  if(tab.value == 'common'){
    // common.value = [];
    common_start.value = 0;
    loadRoomsCommon();
  }else{
    // business.value = [];
    business_start.value = 0;
    loadRoomsBusiness();
  }
}

const createRoom = (external) => {
  if(external){
    $api.getChatRoom(external).then((res) => {
      opened_room.value = res;
      worked.value = true;
    });
  }
}

const addGroupRoom = () => {

if(useAuth().isAuth() && !useAuth().isVerifiedEmail())
  return false;

  $api.postChatRoom(0)
  .then((res) => {
    console.log(res.data.value.data);
    // prepend room to list rooms
    common.value = [res.data.value.data, ...common.value];

    openRoom(common.value[0]);
    // openRoom(res.data.value.data);
  })
  .catch((err) => {
    $popup.error('Error');
  });
  
}


const loadRoom = (id) => {

// if(useAuth().isAuth() && !useAuth().isVerifiedEmail())
//   return false;

  $api.getAddressRoom(id)
  .then((res) => {
    console.log(res.data.value.data);
    // prepend room to list rooms
    // common.value = [res.data.value.data, ...common.value];

    openRoom(res.data.value.data);
    // openRoom(res.data.value.data);
  })
  .catch((err) => {
    $popup.error('Error');
  });

}

const openRoom = (room) => {
  opened_room.value = room;
  worked.value = true;
}

const closeRoom = () => {
  opened_room.value = null;
  worked.value = false;
  reloadRooms();
}

const removeRoom = () => {
  opened_room.value = null;
  worked.value = false;
  reloadRooms();
}

const manageRoom = () => {
  manage.value = true;
}

const inviteUsersRoom = () => {
  invite.value = true;
}

const closeManageRoom = () => {
  manage.value = false;
}

const closeInviteUsers = () => {
  invite.value = false;
}

const clearRefresher = () => {
  if(timer_refresher.value)
    clearInterval(timer_refresher.value);
}

const initRefresher = () => {
  clearRefresher();
  timer_refresher.value = setInterval(() => {
    if(enable.value)
      reloadRooms();
  }, 10000);
}

const toggle = (state) => {
  state = state ? true : false;
  if(state){
    // $noscroll.on()
  }else{
    $noscroll.off()
  }
  // console.log('chat:toggle' + enable.value);
  // console.log('chat:toggle' + state);
  enable.value = state;
  console.log(state);
  if(enable.value)
    loadRooms(); 
  useChatOpened().value = state;

  // console.log('state');
  // console.log(state);
  
}

const initHooks = () => {
  
  if(useChatStarted().value)
    return false;

  hook('chat:close', (id) => {
    toggle(false);
  })

  hook('chat:start', (id) => {
    if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  

    if(!useAuth().isVerifiedEmail()){
      $popup.error('You need to verify your email');
      return false;
    }
      
    if(id){ 
      console.log('chat:start:' + id);
      loadRoom(id);
      toggle(true);
    }
  }) 
}

if(useChatStarted().value){
 
}


onMounted(() => {

  initHooks();
  useChatStarted().value = true;
  initRefresher();
})

onUnmounted(() => {
  clearRefresher();
  $noscroll.off()
})


onBeforeUnmount(() => {
  $noscroll.off();
});



</script>
 


<style scoped>
  

.message_sidebar {
  position: fixed;
  right: -360px;
  top: 0px;
  height: 100%;
  width: 360px;
  background-color: var(--un-background-color);
  /* box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.32); */
  z-index: 107;
  transition: all 0.2s ease-in-out;
}


.message_sidebar.enable {
  right: 0px;
  z-index: 108;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.32);
}


/* @media (min-width: 700px) { */
  .message_sidebar.worked.enable {
    width: 700px;
  }
/* } */


/* 
@media (max-width: 700px) {
  .message_sidebar.worked .error_msg {
    display: none;
  } 
} */

@media (max-width: 768px) {
  .message_sidebar.enable {
    width: 100%;
  }
  .message_sidebar.worked.enable {
    width: 100%;
  }

  .message_sidebar .back_mobile {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: absolute;
    font-size: 1.1rem;
  }
  
}




.message_sidebar.is_guest>.room>.title .options,
.message_sidebar.is_guest .title_rooms .add_group,
.message_sidebar.is_guest>.search_user{  
  pointer-events: none;
}

</style>